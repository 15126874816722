import { memo, useState } from "react";
import "./Newservices.css";
import LazyLoad from "react-lazy-load";
import Fade from "react-reveal/Fade";

function Newservicebanneer({ one, two }) {
  const [name, setNames] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  function sender() {
    let url = "https://wa.me/91+919342595404?text=";
    url =
      `${url}` +
      "Name " +
      `${name}` +
      "%0a" +
      "Email: " +
      `${email}` +
      "%0a" +
      "Message: " +
      `${message}`;
    window.open(url, "_blank").focus();
    setNames("");
    setEmail("");
    setMessage("");
  }
  const menu = [
    {
      name: "20k+",
      num: "Happy Patients",
      img: "../../photo/Oncology.png",
    },
    {
      name: "13+",
      num: "OPD Departments",
      img: "../../photo/Oncology.png",
    },
    {
      name: "23+",
      num: "Experienced Doctors",
      img: "../../photo/Oncology.png",
    },
  ];
  return (
    <>
      <div className="topperseers">
        <div className="container topperseer">
          <div className="row py-5">
            <div className="col-xl-4 col-md-6 col-12 textinthe">
              <h4>{one}</h4>
              <p>{two}</p>
            </div>
            <div className="col-xl-4 hiddeb">
              <LazyLoad className="w-100 centerimg h-100">
                <img className="w-50" src="../../photo/Framelogo.png" alt="" />
              </LazyLoad>
            </div>
            <div className="col-xl-4 col-md-6 col-12">
              <from className="serverssss">
                <input
                  onChange={(e) => setNames(e.target.value)}
                  className="inputers"
                  type="text"
                  placeholder="   Name"
                />
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  className="inputers"
                  type="text"
                  placeholder="   Phone"
                />
                <textarea
                  onChange={(e) => setMessage(e.target.value)}
                  className="inputerss"
                  type="text"
                  placeholder="   Message"
                />
                <div className="subber">
                  <button className="submiter">Submit</button>
                </div>
              </from>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="secservice">
            <div className="container topperseer py-5">
                <div className="row">
                    <div className="col-xl-4 alalaal">
                        <div className="w-100 ramacharia mb-4">
                            <h1><strong>Intervenaonal Radiology</strong></h1>
                            <p>Radiology is a medical specialty that focuses on the use of medical imaging techniques to diagnose and treat various diseases and conditions. Radiologists are physicians who specialize in interpreting medical images and providing diagnostic reports to assist in patient care.</p>
                        </div>
                        <a href="" className="callersinservice">Call Us: 80486 57886</a>
                    </div>
                    <div className="col-xl-4 col-md-6 col-12 textinthe alianphotor alalaal">
                        <LazyLoad className="w-100 alainercenter">
                            <img src="../photo/doctoro.png" alt="" />
                        </LazyLoad>
                        <div className="ramachari">
                            <h5><strong>Dr. Bhaskar MV</strong></h5>
                            <p>MBBS, MD, (Gold Medal), DNB,DM (NIMHANS),FRCR(London), NMANS(Singapore), EBIR,EDIR</p>
                            <p><strong>10 Years of Experience</strong></p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6 col-12 anuman alalaal">
                        <h5>Book An Appointment</h5>
                        <from className="serverssss">
                            <input className="inputers" type="text" placeholder="   Name" />
                            <input className="inputers" type="text" placeholder="   Phone" />
                            <textarea className="inputerss" type="text" placeholder="   Message" />
                            <div className="subber">
                                <button className="submiter">Submit</button>
                            </div>
                        </from>
                    </div>
                </div>

                <div className="row">
                    {
                        menu.map((elem) => {
                            const { name, num, img } = elem
                            {
                                return (<>
                                    <div className="col-lg-4 col-md-6 col-12 borderra mt-3">
                                        
                                    <Fade top>
                                        <div className="numinfo roandsize">
                                            <div className="numberinfot">
                                                <h2>{name}</h2>
                                                <h5>{num}</h5>
                                            </div>
                                            <div className="imgeininfo">
                                                <LazyLoad className="w-100">
                                                    <img className="w-100" src={img} alt="" />
                                                </LazyLoad>
                                            </div>
                                        </div>
                                        </Fade>
                                    </div>
                                </>)
                            }
                        })
                    }
                </div>
            </div>
        </div> */}
    </>
  );
}
export default memo(Newservicebanneer);
