import { memo } from "react"
import { Link } from "react-router-dom"

function Media(){
    return (<>
    <section className="secmedia">
        <div className="container">
            <div className="row">
                <div className="col-12 latesannu mb-2">
                    <h6>MEDIA CENTER</h6>
                    <h1>Latest Announcement</h1>
                </div>
                <div className="col-12">
                    <div className="mb-4">
                        <p className="mb-1">June 13th,2022</p>
                        <Link className="marlikm">What Conditions does a urologist treat?</Link>
                    </div>
                    <div className="mb-4">
                        <p className="mb-1">June 13th,2022</p>
                        <Link className="marlikm">Teenage Health Problems and How to Deal with Them</Link>
                    </div>
                    <div className="mb-2">
                        <p className="mb-1">June 13th,2022</p>
                        <Link className="marlikm">Taking a Break from All Your Worries</Link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>)
}
export default memo(Media)