import Twoser from "../Newserfolder/Subservices/Twoser";
import EightSub from "./SubBlog/EightSub";
import FiveSub from "./SubBlog/FiveSub";
import FourSub from "./SubBlog/FourSub";
import FourtenSub from "./SubBlog/FourtenSub";
import LevenSub from "./SubBlog/LevenSub";
import NineSub from "./SubBlog/NineSub";
import OneSub from "./SubBlog/OneSub";
import Seven from "./SubBlog/Seven";
import SixSub from "./SubBlog/SixSub";
import TenSub from "./SubBlog/TenSub";
import ThreeSub from "./SubBlog/ThreeSub";
import ThreetenSub from "./SubBlog/ThreetenSub";
import TwlveSub from "./SubBlog/TwlveSub";
import TwoSub from "./SubBlog/TwoSub";

const Subin=[
    {
        name:"What Conditions does a urologist treat?",
        address:<OneSub/>
    },
    {
        name:"Psychological Treatment: Do You Really Need It? This Will Help You Decide!",
        address:<TwoSub/>
    },
    {
        name:"Everything about Cardiac Arrest",
        address:<ThreeSub/>
    },
    {
        name:"4 Common Diabetes – Related Fears People Have and How to Overcome Them",
        address:<FourSub/>
    },
    {
        name:"Benefits of Exercise for a Healthy Living",
        address:<FiveSub/>
    },
    {
        name:"Covid Vaccines and their Protective Power",
        address:<SixSub/>
    },
    {
        name:"Doctors to be Consulted for Back Pain",
        address:<Seven/>
    },
    {
        name:"The Most Common Diseases of Childhood",
        address:<EightSub/>
    },
    {
        name:"Teenage Health Problems and How to Deal with Them",
        address:<LevenSub/>
    },
    {
        name:"Accredited Surgical Facility",
        address:<TenSub/>
    },
    {
        name:"Taking a Break from All Your Worries",
        address:<TwlveSub/>
    },
    {
        name:"7 Ways to Get More Sleep Naturally",
        address:<ThreetenSub/>
    },
    {
        name:"Protect Your Health from Covid 19",
        address:<FourtenSub/>
    },
    {
        name:"Different Types of Spine Surgery",
        address:<NineSub/>
    },
]
export default Subin;