import { Link } from "react-router-dom";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./inner.css";
import LazyLoad from "react-lazy-load";
import { useEffect, useState } from "react";
function Hospitalinfo() {
  const [index, setIndex] = useState(0);
  const [two, setTwo] = useState(2);
  const [number, setNumber] = useState(0);
  useEffect(() => {
    function first() {
      setIndex(index + 1);
      setTwo(2);
      const ffirst = setTimeout(() => {}, 2000);
      return () => {
        clearTimeout(ffirst);
      };
    }
    function second() {
      setTwo(0);
      setIndex(0);
    }
    const timer = setInterval(() => {
      async function iamgod() {
        if (window.innerWidth > 1200) {
          setNumber(16.6666);
        } else if (window.innerWidth > 992) {
          setNumber(16.6666);
        } else if (window.innerWidth > 768) {
          setNumber(33.3333);
        } else if (window.innerWidth > 576) {
          setNumber(50);
        } else {
          setNumber(100);
        }
        first();
        if (index > 6) {
          second();
        }
      }
      iamgod();
    }, 3000);
    return () => {
      clearInterval(timer);
    };
  });
  const changers = {
    transform: `translateX(-${index * number}%)`,
    transition: `${two}s`,
  };

  return (
    <>
      <div className="section py-5 homeinback">
        <div className="container">
          <div className="rowhomehi">
            <div style={changers} className="rowhome">
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12 seriocn">
                <div className="filtersha">
                  <img src="../photo/advanced-search.png" alt="" />
                  <a className="textdicore" href="tel:+919342595404">
                    <h6>Advanced Modular Hybrid OT</h6>
                  </a>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12 seriocn">
                <div className="filtersha">
                  <img src="../photo/ecg.png" alt="" />
                  <a className="textdicore" href="tel:+919342595404">
                    <h6>Digital,ECG, ECHO, & TMT</h6>
                  </a>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12 seriocn">
                <div className="filtersha">
                  <img src="../photo/healthcare.png" alt="" />
                  <a className="textdicore" href="tel:+919342595404">
                    <h6>ICU & Critical Care</h6>
                  </a>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12 seriocn">
                <div className="filtersha">
                  <img src="../photo/arthritis.png" alt="" />
                  <a className="textdicore" href="tel:+919342595404">
                    <h6>Orthopedics & Joints Replacement</h6>
                  </a>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12 seriocn">
                <div className="filtersha">
                  <img src="../photo/ultrasound-machine.png" alt="" />
                  <a className="textdicore" href="tel:+919342595404">
                    <h6>Doppler & Ultrasound</h6>
                  </a>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12 seriocn">
                <div className="filtersha">
                  <img src="../photo/technological.png" alt="" />
                  <a className="textdicore" href="tel:+919342595404">
                    <h6>Advanced Automated Laboratory</h6>
                  </a>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12 seriocn">
                <div className="filtersha">
                  <img src="../photo/premium-quality.png" alt="" />
                  <a className="textdicore" href="tel:+919342595404">
                    <h6>Premium Private Suite</h6>
                  </a>
                </div>
              </div>

              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12 seriocn">
                <div className="filtersha">
                  <img src="../photo/advanced-search.png" alt="" />
                  <a className="textdicore" href="tel:+919342595404">
                    <h6>Advanced Modular Hybrid OT</h6>
                  </a>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12 seriocn">
                <div className="filtersha">
                  <img src="../photo/x-rays.png" alt="" />
                  <a className="textdicore" href="tel:+919342595404">
                    <h6>Digital,ECG, ECHO, & TMT</h6>
                  </a>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12 seriocn">
                <div className="filtersha">
                  <img src="../photo/ecg.png" alt="" />
                  <a className="textdicore" href="tel:+919342595404">
                    <h6>ICU & Critical Care</h6>
                  </a>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12 seriocn">
                <div className="filtersha">
                  <img src="../photo/arthritis.png" alt="" />
                  <a className="textdicore" href="tel:+919342595404">
                    <h6>Orthopedics & Joints Replacement</h6>
                  </a>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12 seriocn">
                <div className="filtersha">
                  <img src="../photo/ultrasound-machine.png" alt="" />
                  <a className="textdicore" href="tel:+919342595404">
                    <h6>Doppler & Ultrasound</h6>
                  </a>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12 seriocn">
                <div className="filtersha">
                  <img src="../photo/technological.png" alt="" />
                  <a className="textdicore" href="tel:+919342595404">
                    <h6>Advanced Automated Laboratory</h6>
                  </a>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12 seriocn">
                <div className="filtersha">
                  <img src="../photo/premium-quality.png" alt="" />
                  <a className="textdicore" href="tel:+919342595404">
                    <h6>Premium Private Suite</h6>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="tittle hidertexter">
            <h2 style={{ fontSize: "42px" }}>Welcome To Adhventha Hospital</h2>
            <h2 style={{ fontSize: "28.5px" }}>
              Best multispeciality hospital in South Bangalore
            </h2>
          </div>
          <div className="row">
            <div className="col-xl-6 firstbox">
              <div>
                <div className="mainname mb-4">
                  <h5>
                    “Experience exceptional care with Adhventha Hospital – where
                    compassion meets expertise in medical diagnostics!”
                  </h5>
                </div>
                <p>
                  We understand that healthcare is not just about treating
                  illnesses, it's about providing compassionate care and
                  cutting-edge medical solutions that cater to the holistic
                  well-being of our patients. Our unwavering commitment to
                  patient-centricity and state-of-the-art technology sets us
                  apart as a leading multispecialty hospital.
                </p>
                <p>
                  Located in the heart of Bangalore, Adhventha Hospital is a
                  renowned multispecialty hospital that brings together a team
                  of expert doctors, skilled medical professionals, and advanced
                  machinery to offer a comprehensive range of healthcare
                  services under one roof. With Years of Experience and a
                  passion for excellence, we have earned a reputation for
                  delivering top-notch medical care across various specialties.
                </p>

                <div className="row hosinfirst">
                  <a
                    className="col-6 whatcall"
                    href="https://wa.me/+919342595404?text=Hello, How can I help you??"
                  >
                    <h6>Whatsapp</h6>
                  </a>
                  <a className="col-6 callwhat" href="tel:+919342595404">
                    <h6>Call</h6>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-6 firstbox">
              <img src="../photo/real-img-one.jpg" alt="" />
            </div>
          </div>
          <div className="row hosinsecond mt-3">
            <a
              className="col-6 whatcall"
              href="https://wa.me/+919342595404?text=Hello, How can I help you??"
            >
              <h6>Whatsapp</h6>
            </a>
            <a className="col-6 callwhat" href="tel:+919342595404">
              <h6>Call</h6>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
export default Hospitalinfo;
