import { memo, useContext } from "react";
import { Link } from "react-router-dom";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ".././inner.css";
import { data1 } from "../../App";

function NewFooter() {
  const setServiceseta = useContext(data1);
  const menu = [
    {
      nmae: "Interventional Radiology",
      img: "../../photo/Radiologya.png",
      to: "/mainservice/radiology",
    },
    {
      nmae: "Orthopaedics",
      img: "../../photo/Orthopaedics.png",
      to: "/mainservice/orthopaedics",
    },
    {
      nmae: "Neurosurgery",
      img: "../../photo/Neurosurgerya.png",
      to: "/mainservice/neurosurgery",
    },
    {
      nmae: "Cardiology",
      img: "../../photo/hart.png",
      to: "/mainservice/cardiology",
    },
    {
      nmae: "Internal Medicine",
      img: "../../photo/medicine.png",
      to: "/mainservice/internalmedicine",
    },
    {
      nmae: "Paediatrics",
      img: "../../photo/Paediatrics.png",
      to: "/mainservice/paediatrics",
    },
  ];
  return (
    <>
      <section className="mtfoot">
        <div className="container">
          <div className="row mt-3 pb-3">
            <div className="col-lg-3 col-md-6">
              <div className="newfootlo mb-3">
                <img src="../../photo/serva.jpg" alt="" />
              </div>
              <div className="textfoot mb-3">
                <h6>
                  Experience exceptional care with Adhventha Hospital – where
                  compassion meets expertise in medical diagnostics
                </h6>
              </div>
              <div className="newlinkbox">
                <span className="newliim footle">
                  <img src="../../photo/call.png" alt="" />
                </span>
                <h6>+91 9342595404</h6>
              </div>
              {/* <div className="newlinkbox">
                <span className="newliim footle">
                  <img src="../../photo/call.png" alt="" />
                </span>
                <h6>+91 8088</h6>
              </div> */}
              <div className="newlinkbox emailnewfo">
                <span className="newliim footle">
                  <img src="../../photo/mail.png" alt="" />
                </span>
                <h6>adhventhahospital@gmail.com</h6>
              </div>
              <div className="display-flex iconcenter mb-1">
                <a
                  className="homecall textimg paddimg firsticon"
                  href="https://www.facebook.com/adhventhahospital"
                >
                  <img src="../../photo/facebook.png" alt="" />
                </a>
                <a
                  className="homecall textimg paddimg firsticon"
                  href="https://www.instagram.com/adhventha_hospital/"
                >
                  <img src="../../photo/instagram.png" alt="" />
                </a>
                <a
                  className="homecall textimg paddimg firsticon"
                  href="https://www.google.com/maps/place/Adhventha+Hospital/@12.9175633,77.5754202,17z/data=!3m1!4b1!4m6!3m5!1s0x3bae15858e534d51:0x1f5960042a254008!8m2!3d12.9175633!4d77.5776089!16s%2Fg%2F11khnn6jsd?entry=tts&shorturl=1"
                >
                  <img src="../../photo/google-maps.png" alt="" />
                </a>
                <a
                  className="homecall textimg paddimg firsticon"
                  href="https://www.youtube.com/@adhventhahospitalbangalore"
                >
                  <img src="../../photo/youtube.png" alt="" />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="newdirectli mb-3">
                <h4>Direct Link</h4>
              </div>
              <Link to="/" className="newlinkbox">
                <span className="newliim footle mro">
                  <img src="../../photo/right.png" alt="" />
                </span>
                <h6>Home</h6>
              </Link>
              <Link to="/about" className="newlinkbox">
                <span className="newliim footle mro">
                  <img src="../../photo/right.png" alt="" />
                </span>
                <h6>About Us</h6>
              </Link>
              <Link to="/ourdoctor" className="newlinkbox">
                <span className="newliim footle mro">
                  <img src="../../photo/right.png" alt="" />
                </span>
                <h6>Our Doctors</h6>
              </Link>
              <Link to="/blog" className="newlinkbox">
                <span className="newliim footle mro">
                  <img src="../../photo/right.png" alt="" />
                </span>
                <h6>Blog</h6>
              </Link>
              <Link to="/gallery" className="newlinkbox">
                <span className="newliim footle mro">
                  <img src="../../photo/right.png" alt="" />
                </span>
                <h6>Gallery</h6>
              </Link>
              <Link to="/contact" className="newlinkbox">
                <span className="newliim footle mro">
                  <img src="../../photo/right.png" alt="" />
                </span>
                <h6>Contact Us</h6>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="newdirectli mb-3">
                <h4>Departments</h4>
              </div>
              {menu.map((elem) => {
                const { nmae, img, to } = elem;
                {
                  return (
                    <>
                      <Link
                        to={to}
                        onClick={() => {
                          setServiceseta(nmae);
                        }}
                        className="newlinkbox decoremove"
                      >
                        <span className="newliim footle">
                          <img src={img} alt="" />
                        </span>
                        <h6>{nmae}</h6>
                      </Link>
                    </>
                  );
                }
              })}
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="newdirectli mb-3">
                <h4>Address</h4>
              </div>
              <div className="textfoot mb-3">
                <h6>
                  No 31, 45th Cross Sangam Circle ( Opposite Metro Pillar No 16)
                  Jayanagar 8th Block Bangalore 560070
                </h6>
              </div>
              <div>
                <iframe
                  className="newfootmap"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d497772.61414557794!2d77.58135900000002!3d12.917454!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae157698233193%3A0xbc019cc5c2e18ff6!2sSangam%20Circle!5e0!3m2!1sen!2sus!4v1683705638239!5m2!1sen!2sus"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="a073f87 pb-3 footerditail">
        <a href="" className="footalik a073f87 col-12">
          <div>
            <div
              style={{ marginBottom: "0px", paddingBottom: "0px" }}
              className="footceter"
            >
              <span
                style={{ padding: "0px", border: "0px" }}
                className="mainnamespin headicopad b7cdd0"
              >
                <img
                  style={{ width: "28px" }}
                  src="../../photo/copyright (1).png"
                  alt=""
                />
              </span>
              <p className="appaddlink textdenew">
                Adhventha Hospital, Design By
              </p>
            </div>
            <div
              style={{ margin: "0px", padding: "0px" }}
              className="footceter"
            >
              <p className="appaddlink"> appaddindia.com </p>
            </div>
          </div>
        </a>
      </div>
    </>
  );
}
export default memo(NewFooter);
