import { Link } from "react-router-dom";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"

function Aboutdoctor(){
    return (<>
        <div className="section serback">
            <div className="container">
                <div className="tittle">
                    <h4>ABOUT OUR MEDICAL DIRECTOR</h4>
                    <h2>DR. BHASKAR M V</h2>
                    <div className="row education">
                        <h6>MD (RD), DNB (RD), DM (NIR), FRCR (UK), MNAMS</h6>
                    </div>
                </div>
                <div className="row">
                    <p>Dr. Bhaskar was born and grew up in a small village called Madivala in Mulabagilu Taluk in a family of farmers. As a child, Bhaskar witnessed the difficulties faced by his village residents, who had to travel about 8 km to the hospital for medical treatment. Bhaskar’s mother recognized his passion for reading and encouraged him to become a doctor from a very young age. Today, Dr. Bhaskar M.V. is a renowned Interventional Radiologist and the founder of Adhventha Hospital, a premier healthcare institution committed to providing exceptional medical diagnostic services with top-notch facilities and a world-class atmosphere.</p>
                    <p>He completed his medical education at the prestigious Bangalore Medical College and Research Institute (BMCRI) and pursued his post-graduation with two gold medals from JJM Medical College. With a core interest in neuroimaging and intervention, he pursued and completed his super-specialty in DM (NIIR) from NIMHANS.</p>
                    <p>Dr. Bhaskar has also completed the prestigious FRCR degree from the Royal College of Radiologists in London, which adds to his extensive qualifications. He has worked as a consultant at Apollo Specialty Hospital in Madurai, where he was the first to perform a mechanical thrombectomy with an excellent outcome in South Tamil Nadu. He has also served in Vikram Hospital, Bengaluru, where he developed a wonderful stroke and endovascular program. In SPARSH Hospitals, he led the Radiology department and specialized in neuroimaging, brain, and spine vascular and non-vascular interventions, acute stroke imaging and interventions, peripheral interventions, vascular malformations, embolization, and fetal MRI.</p>
                    <p>As an academician, Dr. Bhaskar has been invited to multiple regional and national conferences and has contributed to numerous peer-reviewed publications. He is a member of esteemed organizations like the Indian Radiological and Imaging Association (IRIA), Indian Society of Vascular and Interventional Radiology (ISVIR), Indian Society of Neuroradiology (ISNR), Royal College of Radiology (RCR) in London, and National Academy of Medical Sciences (NAMS).</p>
                </div>
            </div>
        </div>
    </>)
}
export default Aboutdoctor;