import { memo, useContext } from "react";
import { Link } from "react-router-dom";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ".././inner.css";
import NewLinkbox from "./NewLinkbox";
import { data1 } from "../../App";
import { data5 } from "../../App";

function Newlinkbox(props) {
  const setServiceseta = useContext(data1);
  const servi = useContext(data5);
  const menu = [
    {
      nmae: "Interventional Radiology",
      img: "../../photo/Radiologya.png",
      to: "/mainservice/radiology",
    },
    {
      nmae: "Orthopaedics",
      img: "../../photo/Orthopaedics.png",
      to: "/mainservice/orthopaedics",
    },
    {
      nmae: "Neurosurgery",
      img: "../../photo/Neurosurgerya.png",
      to: "/mainservice/neurosurgery",
    },
    {
      nmae: "Internal Medicine",
      img: "../../photo/medicine.png",
      to: "/mainservice/internalmedicine",
    },
    {
      nmae: "Cardiology",
      img: "../../photo/hart.png",
      to: "/mainservice/cardiology",
    },
    {
      nmae: "Paediatrics",
      img: "../../photo/Paediatrics.png",
      to: "/mainservice/paediatrics",
    },
    {
      nmae: "Plastic surgery",
      img: "../../photo/Plasticsurgery.png",
      to: "/mainservice/plasticsurgery",
    },
    {
      nmae: "General Surgery",
      img: "../../photo/generals.png",
      to: "/mainservice/generalsurgeon",
    },
    {
      nmae: "OB-GYN",
      img: "../../photo/overy.png",
      to: "/mainservice/obstetrics",
    },
    {
      nmae: "Anesthesiology",
      img: "../../photo/anesthesia.png",
      to: "/mainservice/anesthesiology",
    },
    {
      nmae: "Psychiatry",
      img: "../../photo/Psychiatry.png",
      to: "/mainservice/psychiatry",
    },
    {
      nmae: "Physiotherapy",
      img: "../../photo/Physiotherapy.png",
      to: "/mainservice/physiotherapy",
    },
    {
      nmae: "Urology",
      img: "../../photo/Urology.png",
      to: "/mainservice/urology",
    },
  ];
  return (
    <>
      <div className="container">
        <div className="rowheader">
          <div className="newlogo">
            <img src="../photo/logo.png" alt="" />
          </div>
          <div className="newlinker">
            <ul className="newinnerli">
              <li>
                <Link to="/" className="newlink">
                  <h6>Home</h6>
                </Link>
              </li>
              <li>
                <Link to="/about" className="newlink">
                  <h6>About Us</h6>
                </Link>
              </li>
              <li>
                <Link to="/ourdoctor" className="newlink">
                  <h6>Our Doctors</h6>
                </Link>
              </li>
              <li>
                <Link className="newlink">
                  <h6>Departments</h6>
                </Link>
                <div className="newouter">
                  <div className="newcontainer">
                    <div className="row newtittle">
                      <h4>Departments</h4>
                    </div>
                    <div className="row">
                      {menu.map((elem) => {
                        const { nmae, img, to } = elem;
                        {
                          return (
                            <div className="col-xl-3 col-lg-4">
                              <Link
                                to={to}
                                onClick={() => {
                                  setServiceseta(nmae);
                                }}
                                className="newlinkbox"
                              >
                                <span className="newliim">
                                  <img src={img} alt="" />
                                </span>
                                <h6>{nmae}</h6>
                              </Link>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
              </li>
              {/* <li><Link to="/blog" className="newlink"><h6>BLOG</h6></Link></li> */}
              <li>
                <Link className="newlink">
                  <h6>Media</h6>
                </Link>
                <ul className="hilinkk">
                  <li>
                    <Link
                      style={{ width: "150px" }}
                      to="/gallery"
                      className="newlinkbox"
                    >
                      <h6>Image</h6>
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ width: "150px" }}
                      to="/blog"
                      className="newlinkbox"
                    >
                      <h6>Blog</h6>
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/contact" className="newlink">
                  <h6>Contact Us</h6>
                </Link>
              </li>
              <li className="newcon">
                <img src="../../photo/contact.png" alt="" />
                <div className="newconin">
                  <div>
                    <span className="newlogoca">
                      <img src="../photo/call.png" alt="" />
                      <h6>+91 9342595404</h6>
                    </span>
                  </div>
                  <div>
                    <span className="newlogoca">
                      <img src="../photo/mail.png" alt="" />
                      <h6>adhventhahospital@gmail.com</h6>
                    </span>
                  </div>
                  <div></div>
                </div>
              </li>
              <li className="newconser">
                <img src="../../photo/serva.jpg" alt="" />
              </li>
            </ul>
          </div>
          <div className="newmobme">
            <button
              style={{ border: "0px", backgroundColor: "white" }}
              // onClick={() => {
              //   servi("block");
              // }}
              className="newmenua newmobappt"
            >
              <img src="../../photo/serva.jpg" alt="" />
            </button>
            <a href="tel:9886879469" className="newmenua">
              <img src="../../photo/mob-call.png" alt="" />
            </a>
            <div
              onClick={() => {
                props.setFirst("block");
                props.setThree("none");
              }}
              className="newmenu"
            >
              <div className="dcjhds">
                <img src="../../photo/serva.jpg" alt="" />
              </div>
              <img src="../../photo/menu (2).png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default memo(Newlinkbox);
