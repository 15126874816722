import { memo } from "react"

function Number(props){
    return (<>
    <section className="secnumber">
        <div className="container">
            <div className="row">
                <div style={{display:"flex",justifyContent:"center"}}>
                <span onClick={()=>{
                    let menl=props.index-1
                    if(props.index<2){
                        props.setIndex(3)
                    }else{
                        props.setIndex(menl)
                    }
                }} style={{width:"30px"}} className="newliim footle numcen rigthro">
                    <img style={{width:"16px",height:"16px",marginRight:"0px"}} src="../photo/right-arrow (3).png" alt="" />
                </span>
                <span onClick={()=>{
                    props.setIndex(1)
                }} style={{width:"30px"}} className="newliim footle numcen">
                    <h6>1</h6>
                </span>
                <span onClick={()=>{
                    props.setIndex(2)
                }} style={{width:"30px"}} className="newliim footle numcen">
                    <h6>2</h6>
                </span>
                <span onClick={()=>{
                    props.setIndex(3)
                }} style={{width:"30px"}} className="newliim footle numcen">
                    <h6>3</h6>
                </span>
                <span onClick={()=>{
                    let menl=props.index+1
                    if(props.index>2){
                        props.setIndex(1)
                    }else{
                        props.setIndex(menl)
                    }
                }} style={{width:"30px"}} className="newliim footle numcen">
                    <img style={{width:"16px",height:"16px",marginRight:"0px"}} src="../photo/right-arrow (3).png" alt="" />
                </span>
                </div>
            </div>
        </div>
    </section>
    </>)
}
export default memo(Number)