import { memo } from "react";
import LazyLoad from "react-lazy-load";

function Contactinbox() {
  return (
    <>
      {/* <section className="secchild">
        <div className="container"> */}
      {/* <div className="row alicecenter">
                <div className="col-lg-4 col-md-6 col-12 martitleadd">
                    <div className="col-12 addintittle">
                        <h4>Adhventha Hospital</h4>
                    </div>
                    <div className="col-12 coninboxadd">
                        <h6>Years of Experience</h6>
                        <h6>18 +</h6>
                        <h6>Smiling Clients</h6>
                        <h6>6000 +</h6>
                        <h6>Master Certification</h6>
                        <h6>25</h6>
                        <h6>Happy Staff</h6>
                        <h6>40</h6>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                    <div className="col-12 addintittle">
                        <h4>Contact info</h4>
                    </div>
                    <div className="col-12 coninboxadd">
                        <h6>Phone No.</h6>
                        <h6>+919342595404</h6>
                        <h6>Phone No.</h6>
                        <h6>08+91 9342595404</h6>
                        <h6>Email Address</h6>
                        <h6 className="lineot">adhventhahospital@gmail.com</h6>
                        <h6 className="hidderadd">d</h6>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 imgaddincon">
                    <img src="../photo/firstimghome.jpg" alt="" />
                </div>
            </div> */}
      {/* <div className="row">
                        <div className="col-lg-6 missioncon">
                            <div className="missiobban"></div>
                            <div className="mission">
                                <LazyLoad className="visis">
                                    <img src="../photo/mission.jpg" alt="" />
                                </LazyLoad>
                                <h4 style={{color:"white"}}>Our Mission</h4>
                                <h6 style={{color:"white"}}>To provide patient centric, innvoative and evidence based healthcare solutions. Focus on Preventive approaches to early detection of illness by adopting  best clinical practices, Technologies, Experts resources and adhering to highest standards of quality and safety.</h6>
                            </div>
                        </div>
                        <div className="col-lg-6 missioncon">
                            <div className="vissiobban"></div>
                            <div className="vissionhigh">
                                <div className="mission">
                                <LazyLoad className="visis">
                                    <img src="../photo/vission.jpg" alt="" />
                                </LazyLoad>
                                    <h4 style={{color:"white"}}>Our Vision</h4>
                                    <h6 style={{color:"white"}}>To be a leader in providing highly accessible, affordable, high quality and high-tech health care services to the society.</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                        
        </div>
    </section> */}
      <section className="liver mb-0">
        <div className="col-lg-4 col-12 biggersmall">
          <div className="numberslink">
            <h4>Why Adhventha Hospital?</h4>
            <h6>
              Comprehensive medical care and treatment across various
              disciplines under one roof.
            </h6>
            <a href="tel:+919342595404" className="bookappoint">
              Book Appointment
            </a>
          </div>
        </div>
        <div className="col-lg-4 col-12 bgvision pya-5">
          <div className="p-3 innnerh1">
            <h1>Our Vision</h1>
            <p>
              To be a leader in providing highly accessible, affordable, high
              quality and high-tech health care services to the society.
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-12 bgvision pya-5">
          <div className=" p-3 innnerh1">
            <h1>Our Mission</h1>
            <p>
              To provide patient centric, innvoative and evidence based
              healthcare solutions. Focus on Preventive approaches to early
              detection of illness by adopting best clinical practices,
              Technologies, Experts resources and adhering to highest standards
              of quality and safety.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
export default memo(Contactinbox);
