import { Link } from "react-router-dom";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./inner.css";

function Footer(props) {
  return (
    <>
      <div className="section newfootback">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <ul className="footul">
                <div className="firstheaderbox backmob footceter">
                  <img src="../photo/serva.jpg" alt="" />
                </div>
                <li className="displayer footmar">
                  {/* <span className="mainnamespin highwi headicopad"><img src="../photo/facebook-app-symbol.png" alt="" /></span> */}
                  <p>
                    Experience exceptional care with Adhventha Hospital – where
                    compassion meets expertise in medical diagnostics
                  </p>
                </li>
                <li className="displayer footmar">
                  <span className="mainnamespin highwi headicopad footpadle">
                    <img src="../photo/phone-call (1).png" alt="" />
                  </span>
                  <p>9886879469</p>
                </li>
                <li className="displayer footmar">
                  <span className="mainnamespin highwi headicopad footpadle">
                    <img src="../photo/phone-call (1).png" alt="" />
                  </span>
                  <p>080-48657886</p>
                </li>
                <li className="displayer footmar">
                  <span className="mainnamespin highwi headicopad footpadle">
                    <img
                      src="../photo/black-back-closed-envelope-shape.png"
                      alt=""
                    />
                  </span>
                  <p className="contacsize">adhventhahospital@gmail.com</p>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6">
              <ul className="footul">
                <li className="displayer footmar footceter">
                  <h5>DIRECT LINK</h5>
                </li>
                <li className="displayer footmar">
                  <span className="mainnamespin highwi headicopad footpadle">
                    <img src="../photo/right-arrow (3).png" alt="" />
                  </span>
                  <Link to="/" className="contacsize linkfoot">
                    Home
                  </Link>
                </li>
                <li className="displayer footmar">
                  <span className="mainnamespin highwi headicopad footpadle">
                    <img src="../photo/right-arrow (3).png" alt="" />
                  </span>
                  <Link to="/about" className="contacsize linkfoot">
                    About Us
                  </Link>
                </li>
                <li className="displayer footmar">
                  <span className="mainnamespin highwi headicopad footpadle">
                    <img src="../photo/right-arrow (3).png" alt="" />
                  </span>
                  <Link to="/services" className="contacsize linkfoot">
                    Services
                  </Link>
                </li>
                <li className="displayer footmar">
                  <span className="mainnamespin highwi headicopad footpadle">
                    <img src="../photo/right-arrow (3).png" alt="" />
                  </span>
                  <Link to="/contact" className="contacsize linkfoot">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6">
              <ul className="footul">
                <li className="displayer footmar footceter">
                  <h5>SERVICES</h5>
                </li>
                <li className="displayer footmar">
                  <span className="mainnamespin highwi headicopad footpadle">
                    <img src="../photo/right-arrow (3).png" alt="" />
                  </span>
                  <Link
                    to="/Hospitalservice"
                    onClick={() => {
                      props.setSerselect("Advanced Minimally Access Surgeries");
                    }}
                    className="contacsize linkfoot"
                  >
                    Advanced Minimally Access Surgeries
                  </Link>
                </li>
                <li className="displayer footmar">
                  <span className="mainnamespin highwi headicopad footpadle">
                    <img src="../photo/right-arrow (3).png" alt="" />
                  </span>
                  <Link
                    to="/Hospitalservice"
                    onClick={() => {
                      props.setSerselect(
                        "Non Invasive Pinhole Endovascular Surgeries"
                      );
                    }}
                    className="contacsize linkfoot"
                  >
                    Non Invasive Pinhole Endovascular Surgeries
                  </Link>
                </li>
                <li className="displayer footmar">
                  <span className="mainnamespin highwi headicopad footpadle">
                    <img src="../photo/right-arrow (3).png" alt="" />
                  </span>
                  <Link
                    to="/Hospitalservice"
                    onClick={() => {
                      props.setSerselect("Plastic & Cosmetic Surgeries");
                    }}
                    className="contacsize linkfoot"
                  >
                    Plastic & Cosmetic Surgeries
                  </Link>
                </li>
                <li className="displayer footmar">
                  <span className="mainnamespin highwi headicopad footpadle">
                    <img src="../photo/right-arrow (3).png" alt="" />
                  </span>
                  <Link
                    to="/Hospitalservice"
                    onClick={() => {
                      props.setSerselect("Gastro Intestinal Surgeries");
                    }}
                    className="contacsize linkfoot"
                  >
                    Gastro Intestinal Surgeries
                  </Link>
                </li>
                <li className="displayer footmar">
                  <span className="mainnamespin highwi headicopad footpadle">
                    <img src="../photo/right-arrow (3).png" alt="" />
                  </span>
                  <Link
                    to="/Hospitalservice"
                    onClick={() => {
                      props.setSerselect("Orthopedic Surgery");
                    }}
                    className="contacsize linkfoot"
                  >
                    Orthopedic Surgery
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6">
              <ul className="footul">
                <li className="displayer footmar footceter">
                  <h5>ADDRESS</h5>
                </li>
                <li className="displayer footmar">
                  <span className="mainnamespin highwi headicopad footpadle  b7cdd0">
                    <img src="../photo/maps-and-flags.png" alt="" />
                  </span>
                  <Link className="contacsize linkfoot">
                    No 31, 45th cross sangam circle ( opposite metro pillar no
                    16) jayanagar 8th block Bangalore 560070
                  </Link>
                </li>
                <li>
                  <iframe
                    className="footmap"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d497772.61414557794!2d77.58135900000002!3d12.917454!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae157698233193%3A0xbc019cc5c2e18ff6!2sSangam%20Circle!5e0!3m2!1sen!2sus!4v1683705638239!5m2!1sen!2sus"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </li>
                <li className="displayer footmar footceter">
                  <ul className="headicon footicon">
                    <li>
                      <a href="">
                        <span className="mainnamespin headicopad">
                          <img src="../photo/facebook-app-symbol.png" alt="" />
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <span className="mainnamespin headicopad">
                          <img src="../photo/social (1).png" alt="" />
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <span className="mainnamespin headicopad">
                          <img src="../photo/instagram (6).png" alt="" />
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        <span className="mainnamespin headicopad">
                          <img src="../photo/google-plus-logo.png" alt="" />
                        </span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <a href="" className="footalik">
              <div>
                <div
                  style={{ marginBottom: "0px", paddingBottom: "0px" }}
                  className="footceter"
                >
                  <span
                    style={{ padding: "0px", border: "0px" }}
                    className="mainnamespin headicopad b7cdd0"
                  >
                    <img
                      style={{ width: "28px" }}
                      src="../photo/copyright (1).png"
                      alt=""
                    />
                  </span>
                  <p className="appaddlink">Adhventha Hospital, Design By</p>
                </div>
                <div
                  style={{ margin: "0px", padding: "0px" }}
                  className="footceter"
                >
                  <p className="appaddlink"> appaddindia.com </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
export default Footer;
