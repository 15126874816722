import { memo } from "react";
import "./Newservices.css";
import LazyLoad from "react-lazy-load";
import Fade from "react-reveal/Fade";
import { useState } from "react";
import { useEffect } from "react";

function Servicebannerinfo({ doctor, name, content, menu }) {
  const [conts, setConts] = useState(0);
  const [names, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  useEffect(() => {
    if (doctor.length == 1) {
      setConts(0);
    } else {
      const ffirst = setInterval(() => {
        if (conts < doctor.length - 1) {
          setConts(conts + 1);
        } else {
          setConts(0);
        }
      }, 3000);
      return () => {
        clearInterval(ffirst);
      };
    }
  }, [conts]);
  function sender() {
    let url = "https://wa.me/91+919342595404?text=";
    url =
      `${url}` +
      "Name " +
      `${names}` +
      "%0a" +
      "Email: " +
      `${email}` +
      "%0a" +
      "Message: " +
      `${message}`;
    window.open(url, "_blank").focus();
    setName("");
    setEmail("");
    setMessage("");
  }
  return (
    <div className="secservice">
      <div className="container topperseer py-5">
        <div className="row">
          <div className="col-xl-4 alalaal">
            <div className="w-100 ramacharia mb-4">
              <h1>
                <strong>{name}</strong>
              </h1>
              <p>{content}</p>
              {/* <p>Interventional Radiology is  modern day specialization with new age cutting edge technology  which treats complex medical cases with minimally invasive techniques which once required open surgery with a large incision. They use small, hollow tubes (catheters), miniature instruments and imaging guidance to directly access arteries and veins without making large incisions.</p> */}
            </div>
            <a href="" className="callersinservice">
              Call Us: 80486 57886
            </a>
          </div>
          {doctor.map((elem, num) => {
            const { img, names, quali, experi } = elem;
            if (num == conts) {
              return (
                <>
                  <div className="col-xl-4 col-md-6 col-12 textinthe alianphotor alalaal">
                    <LazyLoad className="w-100 alainercenter alheghterdo animationer mb-2">
                      <img src={img} alt="" />
                    </LazyLoad>
                    <div className="ramachari animationer">
                      <h5>
                        <strong>{names}</strong>
                      </h5>
                      <p>{quali}</p>
                      <p>
                        <strong>{experi}+ Years of Experience</strong>
                      </p>
                    </div>
                  </div>
                </>
              );
            }
          })}
          <div className="col-xl-4 col-md-6 col-12 anuman alalaal">
            <h5>Book An Appointment</h5>
            <from className="serverssss">
              <input
                onChange={(e) => {
                  setName(e.target.value);
                }}
                className="inputers"
                type="text"
                placeholder="   Name"
              />
              <input
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="inputers"
                type="text"
                placeholder="   Phone"
              />
              <textarea
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                className="inputerss"
                type="text"
                placeholder="   Message"
              />
              <div className="subber">
                <button onClick={sender} className="submiter">
                  Submit
                </button>
              </div>
            </from>
          </div>
        </div>

        <div className="row">
          {menu.map((elem) => {
            const { name, num, img } = elem;
            {
              return (
                <>
                  <div className="col-lg-4 col-md-6 col-12 borderra mt-3">
                    <Fade top>
                      <div className="numinfo roandsize">
                        <div className="numberinfot">
                          <h2>{name}</h2>
                          <h5>{num}</h5>
                        </div>
                        <div className="imgeininfo">
                          <LazyLoad className="w-100">
                            <img className="w-100" src={img} alt="" />
                          </LazyLoad>
                        </div>
                      </div>
                    </Fade>
                  </div>
                </>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}

export default Servicebannerinfo;
