import { memo, useContext } from "react"
import LazyLoad from "react-lazy-load"
import { Link } from "react-router-dom"
import { data3 } from "../../App";

function Blogfirstbox(){
    const changers=useContext(data3);
    const menu=[
        {
            img:"../photo/b6.jpg",
            name:"What Conditions does a urologist treat?",
            content:"Diabetes commonly known as life style disorder often leaves its toll upon the mental health of people. People detected with diabetes have to monitor their blood..",
        },
        {
            img:"../photo/b2.jpg",
            name:"Psychological Treatment: Do You Really Need It? This Will Help You Decide!",
            content:"What exactly is a psychiatric disorder? Psychological illnesses, often known as mental disorders, are mental imbalances that lead to persistent specific behavio..",
        },
        {
            img:"../photo/b3.jpg",
            name:"Everything about Cardiac Arrest",
            content:"Cardiac arrest is a medical condition occurs due to sudden cessation of heart functioning. The function of heart is to send oxygenated blood to the body. Cardia..",
        },
        {
            img:"../photo/b1.jpg",
            name:"4 Common Diabetes – Related Fears People Have and How to Overcome Them",
            content:"Modern life being fast and full of stress leaves its toll upon human beings. In most of the metropolitan cities people are suffering from diseases like diabetes..",
        },
        {
            img:"../photo/b4.jpg",
            name:"Benefits of Exercise for a Healthy Living",
            content:"Ever since Covid-19 pandemic had broken out in the world, the developed and developing countries like India engage themselves in preparing its vaccine to protec..",
        },
        {
            img:"../photo/b5.jpg",
            name:"Covid Vaccines and their Protective Power",
            content:"Ever since Covid-19 pandemic had broken out in the world, the developed and developing countries like India engage themselves in preparing its vaccine to protec..",
        },
    ]
    return (<>
    <section className="mb-0">
        <div className="container">
                <div style={{textAlign:"center"}} className="mediaheader mb-4">
                    <h6>MEDIA CENTER</h6>
                    <h1>latest Updates & Articles</h1>
                </div>
            <div className="row">
            {
                        menu.map((elem)=>{
                            const {img, name}=elem
                            {
                                return (<>
                                    <div style={{height:"auto"}} className="col-md-6 col-12 mb-4">
                                        <div style={{backgroundColor:"#dbeafe", height:"100%"}}>
                                        <LazyLoad>
                                            <img style={{width:"100%"}} src={img} alt="" />
                                        </LazyLoad>
                                        <div className="chafirst">
                                            <h5>{name}</h5>
                                            <Link to="/subblog" onClick={()=>{
                            changers(name)
                        }} className="noreinfo absuinfo">MORE INFO</Link>
                                        </div>
                                        </div>
                                    </div>
                                </>)
                            }
                        })
                    }
            </div>
        </div>
    </section>
    </>)
}
export default memo(Blogfirstbox)