import { memo, useEffect, useState } from "react";
import LazyLoad from "react-lazy-load";

function Ourdoctorlist({ names }) {
  const [namey, setNamey] = useState("");
  const menuone = [
    {
      img: "../../photo/doctoro.png",
      names: "Dr. Bhaskar MV",
      duty: "Senior Interventional Radiologist Endovascular Surgeon",
      exper: "15",
      departs: "Interventional Radiologist",
      quali:
        "MBBS, MD, (Gold Medal), DNB,DM (NIMHANS),FRCR(London), NMANS(Singapore), EBIR,EDIR",
      depart: [
        "Interventional Radiologist",
        "Radiologist",
        "Endovascular Surgeon",
      ],
    },
    {
      img: "../../photo/doctoro.png",
      names: "Dr. Ambuj",
      duty: "Orthopaedic Surgeon",
      exper: "8",
      departs: "Orthopedics",
      quali: "MBBS, MD, Fellow in Diabetic Foot Managament (FDFM)",
      depart: ["Orthopedics"],
    },
    {
      img: "../../photo/doctoro.png",
      names: "Dr. Mithun",
      duty: "Orthopaedic Surgeon",
      exper: "8",
      departs: "Orthopedics",
      quali: "MBBS, MD",
      depart: ["Consultant", "Orthopedics"],
    },
    {
      img: "../../photo/doctoro.png",
      names: "Dr. Abilash Srivatsav",
      duty: "Pediatrics Orthopedics",
      exper: "8",
      departs: "Orthopedics",
      quali: "MBBS, MD,Fellow in Pediatric Orthopedics",
      depart: ["Orthopedics"],
    },
    {
      img: "../../photo/doctoro.png",
      names: "Dr. Prajwal",
      duty: "Orthopaedic Surgeon",
      exper: "8",
      departs: "Orthopedics",
      quali: "MBBS, MD",
      depart: ["Orthopedics"],
    },
    {
      img: "../../photo/doctor.png",
      names: "Dr. Indira Devi",
      duty: "Neuro Surgeon",
      exper: "35",
      departs: "Neurosurgery",
      quali: "MBBS, MS, MCH (NIMHANS)",
      depart: ["Neurosurgery"],
    },
    {
      img: "../../photo/doctoro.png",
      names: "Dr. Bharath R",
      exper: "12",
      departs: "Neurosurgery",
      duty: "Neuro Surgeon",
      quali: "MBBS, MCH(NIMHANS), Fellow Epilepsy surgery (USA)",
      depart: ["Neurosurgery"],
    },
    {
      img: "../../photo/doctoro.png",
      names: "Dr. Wahid",
      duty: "Internal Medicine",
      exper: "5",
      departs: "Internal Medicine",
      quali: "MBBS, MD, (Diabetology)",
      depart: ["General Physician", "Internal Medicine"],
    },
    {
      img: "../../photo/doctoro.png",
      names: "Dr. Vikram",
      duty: "Internal Medicine",
      exper: "5",
      departs: "Internal Medicine",
      quali: "MBBS, MD (Fellowship Geriatrics) ",
      depart: ["Internal Medicine", "Internal Medicine"],
    },
    {
      img: "../../photo/doctoro.png",
      names: "Dr. Disha Shetty",
      duty: "Cardiologists",
      exper: "4",
      departs: "Cardiology",
      quali: "MBBS, MD, DM(Gold Medal Jayadeva Institute of Cardiology)",
      depart: ["Cardiology"],
    },
    {
      img: "../../photo/doctoro.png",
      names: "Dr. Haridharshan G.J",
      duty: "Pediatrics",
      exper: "6",
      departs: "Pediatric",
      quali: "MBBS, MD ",
      depart: ["Pediatric"],
    },
    {
      img: "../../photo/doctor.png",
      names: "Dr. Varsha Manohar",
      duty: "Pediatric Neurologists",
      exper: "5",
      departs: "Pediatric",
      quali: "MBBS, MD, Fellow In Pediatrics Neurology",
      depart: ["Pediatric"],
    },
    {
      img: "../../photo/doctoro.png",
      names: "Dr. Somashekar",
      duty: "Aesthetic and Plastic Surgeon",
      exper: "15",
      departs: "Plastic Surgery",
      quali: "MBBS, DNB, MCH",
      depart: ["Plastic Surgery"],
    },
    {
      img: "../../photo/doctoro.png",
      names: "Dr. Deepak M.R",
      duty: "Aesthetic and Plastic Surgeon",
      exper: "15",
      departs: "Plastic Surgery",
      quali: "MBBS, MS, MCH",
      depart: ["Plastic Surgery"],
    },
    {
      img: "../../photo/doctoro.png",
      names: "Dr. Suresh K",
      exper: "5",
      departs: "Plastic Surgery",
      duty: "Aesthetic and Plastic Surgeon",
      quali: "MBBS, MS, MCH, (AIMS)",
      depart: ["Plastic Surgery"],
    },
    {
      img: "../../photo/doctoro.png",
      names: "Dr. Muni Reddy",
      duty: "General Surgeon",
      exper: "13",
      departs: "Gastroenterology",
      quali: "MBBS, MS, MCH",
      depart: ["Laproscopic Surgeon", "Gastroenterology", "General Surgery"],
    },
    {
      img: "../../photo/doctoro.png",
      names: "Dr. Shaik Ahmed",
      duty: "General Surgeon",
      exper: "8",
      departs: "General Surgery",
      quali: " MBBS, MS",
      depart: ["General Surgery"],
    },
    {
      img: "../../photo/doctor.png",
      names: "Dr. Sapna G",
      duty: "Obstetricians and Gynecologists",
      exper: "22",
      departs: "Obstetrics & Gynaecology",
      quali: "MBBS, DGO, DNB, FMAS",
      depart: ["Obstetrics & Gynaecology"],
    },
    {
      img: "../../photo/doctor.png",
      names: "Dr. Pooja R",
      duty: "Obstetricians and Gynecologists",
      exper: "3",
      departs: "Obstetrics & Gynaecology",
      quali: "MBBS, MS",
      depart: ["Obstetrics & Gynaecology"],
    },
    {
      img: "../../photo/doctor.png",
      names: "Dr. Tulsi Nagesh",
      duty: "Anesthesiologists",
      exper: "35",
      departs: "Anesthesiology & Critical Care",
      quali: "MBBS, DA ",
      depart: ["Anesthesiology & Critical Care"],
    },
    {
      img: "../../photo/doctoro.png",
      names: "Dr. Krishna",
      duty: "Anesthesiologists",
      exper: "12",
      departs: "Anesthesiology & Critical Care",
      quali: "MBBS, MD",
      depart: ["Anesthesiology & Critical Care"],
    },
    {
      img: "../../photo/doctoro.png",
      names: "Dr. Lokesh",
      duty: "Anesthesiologists",
      exper: "15",
      departs: "Anesthesiology & Critical Care",
      quali: "MBBS, MD",
      depart: ["Anesthesiology & Critical Care"],
    },
    {
      img: "../../photo/doctor.png",
      names: "Dr. Bhavya",
      duty: "Anesthesiologists",
      exper: "10",
      departs: "Anesthesiology & Critical Care",
      quali: "MBBS, MD",
      depart: ["Anesthesiology & Critical Care"],
    },
    {
      img: "../../photo/doctor.png",
      names: "Dr. Anupama",
      duty: "Psychiatrist",
      exper: "5",
      departs: "Psychiatry",
      quali: "MBBS, MD(CSDMISER)",
      depart: ["Psychiatry"],
    },
    {
      img: "../../photo/doctoro.png",
      names: "Dr. Arvind",
      duty: "Psychotherapist",
      departs: "Psychotherapy",
      exper: "10",
      quali: "BPT, MPT, Fellow in Neuromuscular Therapy",
      depart: ["Psychotherapy"],
    },
    {
      img: "../../photo/doctoro.png",
      names: "Dr. Manjunatha.S",
      duty: "Urologists",
      departs: "Urologists",
      exper: "10",
      quali: "MBBS, MS, MCH",
      depart: ["Urology"],
    },
  ];
  const [first, setFirst] = useState(menuone);
  useEffect(() => {
    const update = menuone.filter((elem) => {
      // return (elem.names===value)
      if (names === "" || names === "All") {
        return elem;
      } else {
        const head = elem.depart;
        return head.includes(names);
      }
    });
    setFirst(update);
  }, [names]);
  return (
    <>
      <section>
        <div className="row"></div>
        <div style={{}} className="row">
          {first.map((elem) => {
            const { img, names, duty, roles, quali, exper, departs } = elem;
            {
              return (
                <>
                  <div className="col-xl-4 col-lg-6 col-md-12 col-12 mt-2 selectbot aliyaramayaaa">
                    <div className="docnameina backcolerd">
                      <div className="row backcolord">
                        <div className="doctorone mb-2 col-sm-4 col-12">
                          <img className="alianer" src={img} alt="" />
                        </div>
                        <div className="textdocone aliyamav col-sm-8 col-12 ">
                          <h4>{names}</h4>
                          <div className="row">
                            <div className="col-12 mb-1">
                              <small>{quali}</small>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 mb-1">
                              <b>{exper}+ Years of Experience</b>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 colorsblue">{departs}</div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                          className="w-100"
                        >
                          <a
                            style={{ textDecoration: "none" }}
                            href={`https://wa.me/91+919342595404?text=Doctor name: ${names} and Department: ${departs}`}
                            className="bookappoint papper"
                          >
                            Book Appointment
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            }
          })}
        </div>
      </section>
    </>
  );
}
export default memo(Ourdoctorlist);
