import { useState } from "react";

function ContactUsinfo() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  function sender() {
    let url = "https://wa.me/91+919342595404?text=";
    url =
      `${url}` +
      "Name " +
      `${name}` +
      "%0a" +
      "Email: " +
      `${email}` +
      "%0a" +
      "Subject: " +
      `${subject}` +
      "%0a" +
      "Message: " +
      `${message}`;
    window.open(url, "_blank").focus();
    setName("");
    setEmail("");
    setSubject("");
    setMessage("");
  }
  return (
    <>
      <div className="section">
        <div className="container">
          <div className="tittle">
            <h4>Contact Us</h4>
            <h2>BOOK AN APPOINTMENT NOW</h2>
          </div>
          <div className="row">
            <div className="col-xl-6 firstbox mb-1">
              <form className="conhomeouter">
                <div>
                  <br></br>
                  <input
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    className="inputhome"
                    placeholder="    Name"
                  ></input>
                </div>
                <div>
                  <br></br>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    className="inputhome"
                    placeholder="    Email"
                  ></input>
                </div>
                <div>
                  <br></br>
                  <input
                    onChange={(e) => {
                      setSubject(e.target.value);
                    }}
                    className="inputhome"
                    placeholder="    Subject"
                  ></input>
                </div>
                <div>
                  <br></br>
                  <textarea
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    className="texthome"
                    placeholder="    Message"
                  ></textarea>
                </div>
                <div className="linkabouth">
                  <div onClick={sender} className="linkabout">
                    {" "}
                    Submit{" "}
                  </div>
                </div>
              </form>
            </div>
            <div className="col-xl-6 firstbox">
              <div>
                <p>
                  At Adhventha Hospital, we are committed to delivering the
                  highest standards of care to our patients with compassion,
                  integrity and cost-effectiveness. Our 24×7 Accident &
                  Emergency Care team is dedicated to providing the best medical
                  assistance to our patients in times of need. We strive to make
                  a positive difference in the lives of our patients with our
                  professional and ethical approach.
                </p>
                <div className="mainname textcenter">
                  <h5>Contact Information</h5>
                </div>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <div className="mainnamespin highwi">
                    <img src="../photo/security-pin.png" alt="" />
                  </div>
                  <h6>
                    No 31, 45th Cross Sangam Circle ( Opposite Metro Pillar No
                    16) Jayanagar 8th Block Bangalore 560070
                  </h6>
                </div>

                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <div className="mainnamespin highwi">
                    <img src="../photo/call.png" alt="" />
                  </div>
                  <h6>+91 9342595404</h6>
                </div>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <div
                    style={{ border: "0px" }}
                    className="mainnamespin highwi"
                  >
                    <img
                      style={{ border: "0px", padding: "0px" }}
                      src="../photo/mail.png"
                      alt=""
                    />
                  </div>
                  <h6 className="contacsize">adhventhahospital@gmail.com</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ContactUsinfo;
