import { memo, useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import ".././inner.css"
import LazyLoad from "react-lazy-load"
import { data3 } from "../../App"

function Homeblog(){
    const changers=useContext(data3);
    const menu=[
        {
            img:"../photo/b1.jpg",
            name:"4 Common Diabetes - Related Fears People Have and How to Overcome Them",
            content:"Diabetes commonly known as life style disorder often leaves its toll upon the mental health of people. People detected with diabetes have to monitor their blood..",
        },
        {
            img:"../photo/b2.jpg",
            name:"Psychological Treatment: Do You Really Need It? This Will Help You Decide!",
            content:"What exactly is a psychiatric disorder? Psychological illnesses, often known as mental disorders, are mental imbalances that lead to persistent specific behavio..",
        },
        {
            img:"../photo/b3.jpg",
            name:"Everything about Cardiac Arrest",
            content:"Cardiac arrest is a medical condition occurs due to sudden cessation of heart functioning. The function of heart is to send oxygenated blood to the body. Cardia..",
        },
        {
            img:"../photo/b4.jpg",
            name:"Benefits of Exercise for a Healthy Living",
            content:"Modern life being fast and full of stress leaves its toll upon human beings. In most of the metropolitan cities people are suffering from diseases like diabetes..",
        },
        {
            img:"../photo/b5.jpg",
            name:"Covid Vaccines and their Protective Power",
            content:"Ever since Covid-19 pandemic had broken out in the world, the developed and developing countries like India engage themselves in preparing its vaccine to protec..",
        },
    ]
    const [index, setIndex]=useState(0)
    const [two, setTwo]=useState(2)
    const [number, setNumber]=useState(0)
    useEffect(()=>{
        function first(){
            setIndex(index+1)
            setTwo(2);
            const ffirst=setTimeout(()=>{
            },2000)
            return (()=>{
                clearTimeout(ffirst)
            })
        }
        function second(){
            setTwo(0);
            setIndex(0);
        }
        const timer=setInterval(()=>{
            async function iamgod(){
                if(window.innerWidth>1200){
                    setNumber(25)
                }else if(window.innerWidth>992){
                    setNumber(33.3333)
                }else if(window.innerWidth>768){
                    setNumber(50)
                }else{
                    setNumber(100)
                }
                first();
                if(index>4){
                    second()
                }
            }
            iamgod()
        },3000)
        return (()=>{
            clearInterval(timer)
        })
    })
    const changer={
        transform:`translateX(-${index*number}%)`,
        transition:`${two}s`,
    }
    return (<>
    <section className="mb5">
        <div className=" relpo">
            <div className=" container relpo blogbox">
            <div className="row rowofblogp">
                <div className="col-xl-3 col-lg-4 col-md-6 homeblog minerblog alinitemcenter widtherhome">
                    <div className="homebloga">
                    <h5>BLOG</h5>
                    <h1>Latest Articles & Upadtes</h1>
                    </div>
                    {/* <div className="headerthree mb-3 posireli">
                        <ul className="headicona">
                            <li><a href=""><span className="headicopada"><img src="../photo/facebook-app-symbol.png" alt="" /></span></a></li>
                            <li><a href=""><span className="headicopada"><img src="../photo/social (1).png" alt="" /></span></a></li>
                            <li><a href=""><span className="headicopada"><img src="../photo/instagram (6).png" alt="" /></span></a></li>
                            <li><a href=""><span className="headicopada"><img src="../photo/google-plus-logo.png" alt="" /></span></a></li>
                        </ul>
                    </div> */}
                </div>
            </div>
            </div>    
            <div className="container">
                <div className="rowhomehi">
                <div style={changer} className="rowhome">
                    {
                        menu.map((elem)=>{
                            const {img, name, content}=elem
                            {
                                return (
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-12 blogarea">
                        <div className="newblog">
                            <img src={img} alt="" />
                        </div>
                        <h5>{name}</h5>
                        <p>{content}</p>
                        <Link to="/subblog" onClick={()=>{
                            changers(name)
                        }} className="linkinnew"><h6>READ MORE</h6></Link>
                    </div>
                                )
                            }
                        })
                    }
                    {
                        menu.map((elem)=>{
                            const {img, name, content}=elem
                            {
                                return (
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-12 blogarea">
                        <LazyLoad className="newblog">
                            <img src={img} alt="" />
                        </LazyLoad>
                        <h5>{name}</h5>
                        <p>{content}</p>
                        <Link to="/subblog" className="linkinnew"><h6>READ MORE</h6></Link>
                    </div>
                                )
                            }
                        })
                    }
                </div>
                </div>
                
            </div>
        </div>
    </section>
    </>)
}
export default memo(Homeblog)